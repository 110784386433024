@import "base/_colors.scss";

body {
  font-family: "Lato", sans-serif;
  font-size: 1em;
  line-height: 1.4;
  background-color: $lightBackground;
  color: $fontColor;
  margin: 0;
  padding: 0;
  min-width: 320px;
}

a {
  cursor: pointer;
  text-decoration: none;
  color: $fontColor;
}

input,
textarea {
  font-family: "Lato", sans-serif;
  background-color: transparent;
}
.accordion-filter {
  .title {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    input{
      padding-right: 10px;
    }
  }
}
